export default {
  user: {},
  teacher: {},
  loadingUser: false,
  onetimeAccessInfo: null, // log in via valid one time access link
  settings: {},
  schoolRoles: [],

  loadingPortalData: true,
  loadingData : true, // loading app public data
  loadingUserFormResponses: true, // profession survey & form responses
  loadingSchoolUserData: true,

  // Work (Seminars & Visits)
  services: [], // EconWork / SurveyWork / ...
  sessions: [], // Seminars / Visits
  ackAppliedEvent: undefined,
  ackInterestedService: undefined,

  // AchieveBot (JUPAS & E-App)
  schools: [],
  absIntakes: [],
  step1Questions: [],
  submittedProfessionResponses: false,
  allElectives: [],
  allSubjects: [],
  allCards: [],

  // Anchor Events
  lessons: [],
  anchorEventTasks: [],
  formQuestions: [],
  userFormResponses: [], // Form Responses (e.g. feedback form)

  // Portal Data
  allProfessions: [], // array of profession objects (e.g. { id: 1, name: "IT Consultant"})
  allSectors: [],
  allDisciplineGroups: [],
  allDisciplines: [],
  allPrograms: [],
  draftPrograms: [],
  allInstitutions: [],
  allSegments: [],
  professionTabs: [],

  // Teacher Data
  schoolStudents: [],
  schoolTeachers: [],
  teacherResponseOptions: [],
  teacherResponseQuestions: [],

  // SLP
  disciplineClaims: [],

  // University Student
  allJobEXes: [],
  userCareerPlan: null,
  userCredentials: [],
  myCredentialsSections: [],
  userSections: [],
  loadingCredentials: true,
  loadingProgram: true,
  currProgram: {},

  // Professor view
  allClients: [],
}